export const personFullName = function (
  firstName: string,
  infix: string,
  lastName: string
): string {
  if (infix === "") {
    return firstName + " " + lastName;
  } else {
    return firstName + " " + infix + " " + lastName;
  }
};
