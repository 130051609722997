import React from "react";
import { WcStack } from "@bpipartners/webui";
import { AppContext } from "@guberno/utils/context";
import { UserForm } from "./forms/UserForm";
import { createUserDefaultValues } from "../utils/createUserValues";
import {
  IamUser,
  TenantAllUserTenant,
  GetAllUserTenantsDocument,
} from "@guberno/data-access";
import { useForm } from "react-hook-form";
import { setCookie } from "nookies";
import { TenantAutoComplete } from "@guberno/composite/tenant";
import { useQuery } from "@apollo/client";

export const UserProfile = () => {
  const { localAppState } = React.useContext(AppContext);
  const { data: tenantsData } = useQuery(GetAllUserTenantsDocument, {
    fetchPolicy: "network-only",
  });
  //@ts-ignore
  const [tenant, setTenant] = React.useState(localAppState.userData.tenantID);
  const [tenantList, setTenantList] = React.useState<TenantAllUserTenant[]>([]);
  const form = useForm<IamUser>({
    mode: "onSubmit",
    //@ts-ignore
    defaultValues: createUserDefaultValues(localAppState.userData),
  });

  React.useEffect(() => {
    if (
      tenantsData &&
      tenantsData.AllUserTenants &&
      tenantsData.AllUserTenants.length > 0
    ) {
      setTenantList(tenantsData.AllUserTenants);
    }
  }, [tenantsData]);

  const handleTenantSelected = (val: TenantAllUserTenant) => {
    if (val) {
      setTenant(val.TenantID);
      setCookie(null, "guberno-t", val.TenantID, {
        SameSite: "Strict",
      });
      window.location.href = "/";
    }
  };

  return (
    <WcStack id="UserProfile">
      {tenantList && tenantList.length > 0 && (
        <WcStack padding={5} spacing={2}>
          <TenantAutoComplete
            width={300}
            inputParam={tenant}
            tenants={tenantList}
            onValueChanged={handleTenantSelected}
          />
        </WcStack>
      )}
      <WcStack id="UserModalStack">
        <UserForm
          rolesList={localAppState.userData?.roles}
          form={form}
          formDisabled={true}
        />
      </WcStack>
    </WcStack>
  );
};
