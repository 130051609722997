import React from "react";
export const ISPermittedServer = (
  permission: string,
  userData: any
): boolean => {
  //@ts-ignore

  if (userData) {
    const { permissions } = userData;
    if (permissions) {
      const isTrue = permissions.find((perm: string) => perm === permission);
      return isTrue ? true : false;
    }
  }
  return false;
};
