/* eslint-disable strict */

import {} from "@guberno/data-access";

export interface MenuItem {
  id: string;
  title: string;
  icon: string;
  basePath: string;
  route: string;
  menuChildItem: MenuItem[];
}

export const createMenuRoute = function (
  basePath: string,
  menuItem: MenuItem
): { url: string; clientRoute: boolean } {
  const clientRoute = false;

  //   if (basePath && basePath === menuItem.basePath) {
  //     url = menuItem.route;
  //     clientRoute = true;
  //     //@ts-ignore
  //     return { url, clientRoute };
  //   }
  //@ts-ignore

  const url = menuItem.basePath + menuItem.route;
  return { url, clientRoute };
};
