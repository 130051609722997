import { WcStack, WcTypography } from "@bpipartners/webui";
import { WcTextfieldController } from "@bpipartners/hookformcontroller";
import { IamRole, IamUser } from "@guberno/data-access";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslations } from "next-intl";
import { RolesAutoComplete } from "../RolesAutoComplete";

interface UserFormProps {
  form: UseFormReturn<IamUser>;
  rolesList: IamRole[];
  formDisabled?: boolean;
}

export const UserForm = ({ form, rolesList, formDisabled }: UserFormProps) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = form;

  const t = useTranslations();

  return (
    <WcStack
      id="UserForm"
      width={"100%"}
      direction="column"
      spacing={2}
      padding={5}
    >
      <WcTypography>{t("common.user")}</WcTypography>
      <WcStack width={400} spacing={2}>
        {!getValues("userID") && (
          <WcTextfieldController
            control={control}
            name="emailAddress"
            required
            disabled={formDisabled}
            label={t("labels.emailAddress")}
          />
        )}
        <WcTextfieldController
          control={control}
          name="firstName"
          required
          disabled={formDisabled}
          label={t("labels.firstName")}
        />
        <WcTextfieldController
          control={control}
          name="infix"
          disabled={formDisabled}
          label={t("labels.infix")}
        />
        <WcTextfieldController
          control={control}
          name="lastName"
          required
          disabled={formDisabled}
          label={t("labels.lastName")}
        />
      </WcStack>
      <WcStack>
        <RolesAutoComplete
          width={400}
          control={control}
          disabled={formDisabled}
          inputParam={getValues("roles")}
          roles={rolesList}
          error={errors}
        />
      </WcStack>
    </WcStack>
  );
};
