import React from "react";
import { WcChip, WcStack, WcTextField, WcTypography } from "@bpipartners/webui";
import { WcAutoCompleteController } from "@bpipartners/hookformcontroller";
import { IamUser } from "@guberno/data-access";

interface UserAutoCompleteControllerProps {
  users: IamUser[];
  defaultValue?: any;
  width?: number | string;
  name: string;
  label: string;
  control?: any;
  controlDisabled?: boolean;
  errorMessage?: string;
  required?: boolean;
}

export const UserAutoCompleteController = function ({
  users,
  control,
  name,
  defaultValue,
  errorMessage,
  label,
  width,
  controlDisabled,
  required = false,
}: UserAutoCompleteControllerProps) {
  const [value, setValue] = React.useState<IamUser[]>([]);
  const [options, setOptions] = React.useState<IamUser[]>([]);

  React.useEffect(() => {
    if (users && users.length > 0) {
      setOptions(users);
    } else {
      setOptions([]);
    }
  }, [users]);

  React.useEffect(() => {
    if (defaultValue && defaultValue.length > 0 && users && users.length > 0) {
      const newList = [];

      for (let index = 0; index < defaultValue.length; index++) {
        const element = defaultValue[index];
        const foundUser = users.find(
          (item: IamUser) => item.userID.toString() === element.userID
        );
        if (foundUser) {
          newList.push(foundUser);
        }
      }

      setValue([...newList]);
    } else {
      setValue([]);
    }
  }, [defaultValue, users]);

  const handleUserChange = (inputValue: any) => {
    setValue(inputValue);
  };

  return (
    <WcStack id="UserAutoCompleteController">
      {users && users.length > 0 && (
        <WcAutoCompleteController
          id="async-userAutoComplete"
          control={control}
          customID="userID"
          disabled={controlDisabled}
          disableCloseOnSelect
          sx={{ width: width }}
          onCustomChange={(val) => handleUserChange(val)}
          disableClearable
          multiple={true}
          name={name}
          //@ts-ignore
          getOptionLabel={(option) => option.emailAddress}
          isOptionEqualToValue={(option, val) => option.userID === val.userID}
          // @ts-ignore
          renderOption={(props, option) => (
            <li {...props} key={option.userID}>
              <WcStack width={"100%"}>
                <WcTypography>{option.fullName}</WcTypography>
              </WcStack>
            </li>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <WcChip
                {...getTagProps({ index })}
                key={option.userID}
                disabled={controlDisabled}
                // @ts-ignore
                label={
                  (option && option.fullName) || (option && option.fullName)
                }
              />
            ))
          }
          value={value || null}
          options={options}
          defaultValue={value || null}
          renderInput={(params) => (
            <WcTextField
              label={label}
              {...params}
              disabled={false}
              error={errorMessage && errorMessage.length > 0}
              helperText={errorMessage}
              variant="filled"
              required={required}
            />
          )}
        />
      )}
    </WcStack>
  );
};

export default UserAutoCompleteController;
