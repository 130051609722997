import React from "react";
import { WcChip, WcStack, WcTextField } from "@bpipartners/webui";
import { useTranslations } from "next-intl";
import { WcAutoCompleteController } from "@bpipartners/hookformcontroller";
import { IamRole } from "@guberno/data-access";

interface RolesAutoCompleteProps {
  roles: IamRole[];
  inputParam?: any;
  width?: number;
  disabled?: boolean;
  control?: any;
  error?: any;
}

export const RolesAutoComplete = function (props: RolesAutoCompleteProps) {
  const { roles, control, inputParam, width, error, disabled } = props;
  const t = useTranslations("labels");
  const [value, setValue] = React.useState(inputParam || []);

  return (
    <WcStack id="RolesAutoComplete">
      {roles && roles.length > 0 && (
        <WcAutoCompleteController
          id="async-RolesAutoComplete"
          sx={{ width: width ? width : 600 }}
          control={control}
          disabled={disabled}
          filterSelectedOptions
          name="roles"
          multiple={true}
          //@ts-ignore
          getOptionLabel={(option) => option.roleName}
          isOptionEqualToValue={(option, val) => option.roleID === val.roleID}
          // @ts-ignore
          renderOption={(propsVal: any, option: any) => (
            <li {...propsVal} key={option.roleID}>
              {option.roleName}
            </li>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <WcChip
                {...getTagProps({ index })}
                key={option.roleID}
                label={option.roleName}
              />
            ))
          }
          options={roles}
          defaultValue={inputParam || null}
          renderInput={(params) => (
            <WcTextField
              {...params}
              required
              error={!!error.roles?.message}
              helperText={error.roles?.message}
              variant="filled"
              label={t("role")}
            />
          )}
        />
      )}
    </WcStack>
  );
};

export default RolesAutoComplete;
