import { IamUser } from "@guberno/data-access";

export const createUserDefaultValues = (inputData: IamUser) => {
  const defaultValues: IamUser = {
    userID: (inputData && inputData.userID) || "",
    emailAddress: (inputData && inputData.emailAddress) || "",
    firstName: (inputData && inputData.firstName) || "",
    infix: (inputData && inputData.infix) || "",
    lastName: (inputData && inputData.lastName) || "",
    roles: (inputData && inputData.roles) || [],
    active: (inputData && inputData.active) || true,
  };
  return defaultValues;
};
