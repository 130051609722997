"use client";

import React from "react";
import { IamUser, TenantDetailsByTenantIdRow } from "@guberno/data-access";

interface AppContextproviderProps {
  children:
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | boolean
    | null
    | undefined;
  userData?: IamUser;
  //menuItems?: MenuItem[];
  tenantCompanyDetails?: TenantDetailsByTenantIdRow;
}

interface AppContextState {
  userData?: IamUser;
  enabledFeatures?: string[];
  //menuItems?: MenuItem[];
  tenantCompanyDetails?: TenantDetailsByTenantIdRow;
}

export interface ContextState {
  localAppState: AppContextState;
  setLocalAppState: React.Dispatch<React.SetStateAction<AppContextState>>;
}
export const AppContext = React.createContext<ContextState>({
  localAppState: {},
  setLocalAppState: () => null,
});

export const AppContextProvider = (props: AppContextproviderProps) => {
  const { userData, tenantCompanyDetails } = props;
  const [localAppState, setLocalAppState] = React.useState<AppContextState>({
    userData: userData,
    //menuItems: menuItems,
    //enabledFeatures: enabledFeatures,
    tenantCompanyDetails: tenantCompanyDetails,
    //supportIsAgent: supportIsAgent,
  });
  const context: ContextState = {
    localAppState,
    setLocalAppState,
  };
  return (
    <AppContext.Provider value={context}>{props.children}</AppContext.Provider>
  );
};
export default AppContextProvider;
