"use client";

import { Box, WcModal, WcStack, WcTypography } from "@bpipartners/webui";
import { FetchMenuRow } from "@guberno/data-access";
import React, { useRef } from "react";
import {
  Tree,
  MultiBackend,
  getBackendOptions,
  NodeModel,
  DndProvider,
} from "@minoru/react-dnd-treeview";
import { CustomDrawerNode } from "./CustomDrawerNode.View";
import styles from "./Tree.module.css";
import { usePathname, useRouter } from "next/navigation";
import { useTranslations } from "next-intl";
import { UserProfile } from "@guberno/composite/user";
import { useTheme } from "@guberno/utils/theme";
import { CgProfile } from "react-icons/cg";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { AppContext } from "@guberno/utils/context";
import { personFullName } from "@guberno/utils/helpers";
interface FlatDrawerProps {
  treeData: FetchMenuRow[];
}

const FlatDrawer = ({ treeData }: FlatDrawerProps) => {
  const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;
  const [localTreeData, setLocalTreeData] = React.useState<NodeModel[]>();
  const [selectedNode, setSelectedNode] = React.useState<NodeModel>();
  const t = useTranslations();
  const [initialOpen, setInitialOpen] = React.useState<number[]>([]);
  const [openUserProfile, SetOpenUserProfile] = React.useState(false);
  const [currentID, setCurrentID] = React.useState<number | string>(0);
  const { localAppState } = React.useContext(AppContext);

  const treeRef = useRef(null);

  const pathName = usePathname();
  const router = useRouter();
  const theme = useTheme();

  const handleUserProfileClicked = () => {
    SetOpenUserProfile(!openUserProfile);
  };

  useIsomorphicLayoutEffect(() => {
    if (treeData) {
      const treeList: NodeModel[] = [];
      treeData.forEach((element) => {
        const treeItem: NodeModel = {
          //@ts-ignore
          id: element.menuID,
          parent: element.parentID || 0,
          //@ts-ignore
          text: element.name,
          //@ts-ignore
          order: element.menuOrder,
          droppable: false,
          data: {
            type: element.type,
            route: element.route,
            icon: element.icon,
          },
        };
        treeList.push(treeItem);
      });

      setLocalTreeData([...treeList]);
      if (pathName) {
        const splitPath = pathName.split("/");
        const lastNumber = splitPath[splitPath.length - 1];
        const mainPath = "/" + splitPath[1] + "/" + splitPath[2];
        if (lastNumber === "") {
          setSelectedNode(treeList?.find((item) => item.text === "home"));
        } else {
          const findFullItem = treeList?.find(
            //@ts-ignore
            (item) => item.data.route === pathName
          );

          if (findFullItem) {
            setFoundItem(findFullItem);
          } else {
            const findFirstLevelItem = treeList?.find(
              //@ts-ignore
              (item) => item.data.route === mainPath
            );
            if (findFirstLevelItem) {
              setFoundItem(findFirstLevelItem);
            }
          }
        }
      }
    }
  }, [treeData, pathName]);

  const setFoundItem = (findItem) => {
    setSelectedNode(findItem);
    setInitialOpen([
      parseInt(findItem.parent.toString(), 10),
      parseInt(findItem.id.toString(), 10),
    ]);
  };

  const handleLogoutClick = () => {
    window.location.href = "/api/logout";
  };

  const handleOnSelect = (node: NodeModel) => {
    //@ts-ignore
    if (node && node.data.type === "link") {
      setSelectedNode(node);
      //@ts-ignore
      router.push(node.data.route);
    } else {
      switch (node.text) {
        case "userProfile":
          handleUserProfileClicked();
          break;

        default:
          handleLogoutClick();
          break;
      }
    }
  };

  return (
    <WcStack
      width={theme.dimensions.appDrawerWidth}
      minWidth={theme.dimensions.appDrawerWidth}
      height={"100%"}
      id="FlatDrawer"
      paddingLeft={8}
      paddingRight={8}
      paddingTop={8}
      sx={{
        backgroundColor: theme.appPalette.primary[99],
      }}
    >
      <WcStack
        id="FlatDrawerMainStack"
        height={"100%"}
        justifyContent={"space-between"}
      >
        <WcStack
          height={"100%"}
          sx={{
            overflowY: "scroll",
            overflowX: "hidden",
            scrollbarWidth: "none",
            "&::MsOverflowStyle": "none",
            "&::-webkit-scrollbar": {
              width: 0,
              display: "none",
            },
          }}
        >
          <WcStack mb={3}>
            <Box
              component="img"
              sx={{
                width: 190,
                paddingLeft: 4,
              }}
              //@ts-ignore
              alt="Capensis logo"
              src="https://storage.googleapis.com/capensis-pub/Capensis-Logo_Gradient.png"
            />
          </WcStack>
          {localTreeData && localTreeData.length > 0 && (
            <DndProvider backend={MultiBackend} options={getBackendOptions()}>
              <Tree
                tree={localTreeData}
                rootId={0}
                ref={treeRef}
                initialOpen={initialOpen}
                enableAnimateExpand={true}
                insertDroppableFirst={false}
                render={(node, { onToggle, isOpen, ...rest }) => (
                  <CustomDrawerNode
                    //@ts-ignore
                    node={node}
                    isSelected={node.id === selectedNode?.id}
                    {...rest}
                    onToggle={(id) => {
                      if (currentID === node.id) {
                        setCurrentID(0);
                        treeRef.current?.closeAll();
                      } else {
                        treeRef.current?.closeAll();
                        setCurrentID(id);
                        setTimeout(() => {
                          treeRef.current?.open(id);
                        }, 5);
                      }
                    }}
                    onSelect={handleOnSelect}
                    isOpen={isOpen}
                  />
                )}
                dragPreviewRender={() => null}
                canDrag={() => false}
                canDrop={() => false}
                sort={false}
                onDrop={() => {}}
                classes={{
                  root: styles.treeRoot,
                  draggingSource: styles.draggingSource,
                  dropTarget: styles.dropTarget,
                  listItem: styles.listStyle,
                  placeholder: styles.placeholderContainer,
                  container: styles.container,
                }}
              />
            </DndProvider>
          )}
        </WcStack>
        <WcStack
          id="ProfileStack"
          height="150px"
          borderTop={"1px solid lightgray"}
          paddingBottom={8}
        >
          <WcStack padding={4} sx={{ color: theme.appColors.primary }}>
            <WcTypography variant="body1">
              {localAppState.tenantCompanyDetails?.name}
            </WcTypography>
          </WcStack>
          <WcStack
            direction={"row"}
            sx={{
              width: "100%",
              color: theme.appColors.primary,
              borderRadius: 8,
              padding: 4,
              alignItems: "center",
              cursor: "pointer",
              "&:hover": {
                background: theme.appPalette.primary[95],
                color: theme.appColors.primary,
              },
            }}
            height={theme.dimensions.appDrawerItemHeight}
          >
            <WcStack direction={"row"} width={"100%"} alignItems={"center"}>
              <WcStack
                width={"100%"}
                spacing={2}
                direction={"row"}
                alignItems={"center"}
                onClick={handleUserProfileClicked}
              >
                <WcStack width={"20px"}>
                  <CgProfile size={20} />
                </WcStack>
                {/* </WcIcon> */}
                <WcTypography variant="body1">
                  {personFullName(
                    localAppState.userData.firstName,
                    localAppState.userData.infix,
                    localAppState.userData.lastName
                  )}
                </WcTypography>
              </WcStack>
            </WcStack>
          </WcStack>
          <WcStack
            direction={"row"}
            sx={{
              width: "100%",
              color: theme.appColors.primary,
              borderRadius: 8,
              padding: 4,
              alignItems: "center",
              cursor: "pointer",
              "&:hover": {
                background: theme.appPalette.primary[95],
                color: theme.appColors.primary,
              },
            }}
            height={theme.dimensions.appDrawerItemHeight}
          >
            <WcStack direction={"row"} width={"100%"} alignItems={"center"}>
              <WcStack
                width={"100%"}
                spacing={2}
                direction={"row"}
                alignItems={"center"}
                onClick={handleLogoutClick}
              >
                <WcStack width={"20px"}>
                  <RiLogoutBoxRLine size={20} />
                </WcStack>
                {/* </WcIcon> */}
                <WcTypography variant="body1">
                  {t("labels.logOut")}
                </WcTypography>
              </WcStack>
            </WcStack>
          </WcStack>
        </WcStack>
      </WcStack>

      {openUserProfile && (
        <WcModal
          open={openUserProfile}
          modalTitle={t("common.userProfile")}
          closeHandler={handleUserProfileClicked}
        >
          <UserProfile />
        </WcModal>
      )}
    </WcStack>
  );
};
export default FlatDrawer;
