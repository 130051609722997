import React from "react";
import {
  WcAutoComplete,
  WcChip,
  WcStack,
  WcTextField,
} from "@bpipartners/webui";
import { useTranslations } from "next-intl";
import { TenantAllUserTenant } from "@guberno/data-access";

interface RolesAutoCompleteProps {
  tenants: TenantAllUserTenant[];
  inputParam?: any;
  width?: number;
  onValueChanged: (tenantID) => void;
}

export const TenantAutoComplete = function (props: RolesAutoCompleteProps) {
  const { tenants, inputParam, width, onValueChanged } = props;
  const t = useTranslations("labels");
  const [value, setValue] = React.useState(null);

  const onChange = function (inputVal: any) {
    onValueChanged(inputVal);
    setValue(inputVal);
  };

  React.useEffect(() => {
    if (inputParam) {
      //@ts-ignore
      const foundItem = tenants.find((item) => item.TenantID === inputParam);

      if (foundItem) {
        setValue(foundItem);
      }
    }
  }, [inputParam, tenants]);

  return (
    <WcStack id="TenantAutoComplete">
      {tenants && tenants.length > 0 && (
        <WcAutoComplete
          id="async-TenantAutoComplete"
          sx={{ width: width ? width : 600 }}
          disabled={false}
          onChange={(_, inputValue) => onChange(inputValue)}
          filterSelectedOptions
          multiple={false}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, val) =>
            option.TenantID === val.TenantID
          }
          renderOption={(propsVal: any, option: any) => (
            <li {...propsVal} key={option.TenantID}>
              {option.name}
            </li>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <WcChip
                {...getTagProps({ index })}
                key={option.TenantID}
                label={option.name}
              />
            ))
          }
          value={value}
          options={tenants}
          renderInput={(params) => (
            <WcTextField
              {...params}
              required
              variant="filled"
              label={t("tenant")}
            />
          )}
        />
      )}
    </WcStack>
  );
};

export default TenantAutoComplete;
